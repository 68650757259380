






























































































































































































































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import ListView from '@/components/list/ListView.vue'
import PageTab from '@/components/ui/PageTab.vue'
import { appendSitePrefix } from '@/utils/routeUtils'
import { formatDateTimeString } from '@/utils/dateUtils'
import { Watch } from 'vue-property-decorator'
import { vxm } from '@/store'
import SubTab from '@/models/ui/SubTab'
import b64toBlob from 'b64-to-blob'

@Component({
  components: {
    ListView,
    PageTab,
  },
})
export default class List extends Vue {
  private appendSitePrefix = appendSitePrefix
  private formatDateTimeString = formatDateTimeString

  private listViewKey = 0

  private additionalFilters = []

  private orderTypeAdmin = 'admin'
  private orderTypeExternal = 'external'
  private orderTypeSupplier = 'supplier'
  private orderTypeCredit = 'credit'
  private orderTypeWebshopPreOrder = 'webshop_pre_order'

  private tabModel = 0
  private subTabModel = 0

  private headers = []

  private topActions = []
  private selectActions = []
  private rowActions = []

  private showDetailsCheckbox = false
  private onlyStarredCheckbox = false
  private hideBookingsWithoutGoodsCheckbox = false

  private exportExcelLoading = false
  private search = ''
  private loadingData = true
  private hasWebshopReservationOrders = false
  private hasWebshopPreOrders = false
  private hasMultipleWebshops = false
  private canCancelManyOrders = false
  private isSuperUser = false
  private isAdmin = false
  private canSeeExternalOrders = false
  private canSeeSupplierOrders = false
  private canSeeCreditOrders = false
  private supplierConnections = []
  private webshops = []

  private chooseSupplierDialog = false
  private countries = []
  private choseSupplierDialogShowNotFoundSupplierInfo = false
  private choseSupplierDialogSupplierId = null
  private choseSupplierDialogCurrency = null
  private tags = []

  private cancelOrdersDialog = false
  private cancelOrdersModel = {
    ids: [],
    cancelOrder: false,
    returnProductsToStock: false,
    cancelBooking: false,
    cancelBatchInvoicing: false,
    cancelPickingRequest: false,
    emailCustomer: false,
  }

  private selectedRows = []

  private created() {
    /*
    todo: try to use this instead from initial-data
    this.hasWebshopReservationOrders = vxm.user.hasFeature('WebshopReservationOrders')
    this.hasWebshopPreOrders = vxm.user.hasFeature('WebshopPreOrders')
    this.canCancelManyOrders = vxm.user.hasFeature('CanCancelManyOrders')
    this.isSuperUser = vxm.user.hasFeature('IsSuperUser')
    this.isAdmin = vxm.user.hasFeature('IsAdmin')
    this.canSeeExternalOrders = vxm.user.hasFeature('SeeExternalOrders')
    this.canSeeSupplierOrders = vxm.user.hasFeature('SeeSupplierOrders')
    this.canSeeCreditOrders = vxm.user.hasFeature('SeeCreditOrders')
     */

    this.$axios.get(this.url + '/initial-data').then((response) => {
      const data = response.data

      this.hasWebshopReservationOrders = data.hasWebshopReservationOrders
      this.hasWebshopPreOrders = data.hasWebshopPreOrders
      this.canCancelManyOrders = data.canCancelManyOrders
      this.isSuperUser = data.isSuperUser
      this.isAdmin = data.isAdmin
      this.canSeeExternalOrders = data.canSeeExternalOrders
      this.canSeeSupplierOrders = data.canSeeSupplierOrders
      this.canSeeCreditOrders = data.canSeeCreditOrders

      this.webshops = data.webshops
      this.hasMultipleWebshops = this.webshops.length > 1
      this.supplierConnections = data.supplierConnections
      this.countries = data.countries
      this.choseSupplierDialogCurrency = data.shopCurrency
      this.tags = data.tags
      this.loadingData = false

      this.routeToParams()
    })

    this.rowActions = [
      {
        id: 'view',
        route: (item) => {
          return this.appendSitePrefix('/' + item.id + '/order2/main')
        },
      },
    ]
  }

  private setTopActions() {
    this.topActions = []
    this.selectActions = []

    if (
      this.orderTypes[this.tabModel] !== this.orderTypeExternal &&
      this.orderTypes[this.tabModel] !== this.orderTypeWebshopPreOrder
    ) {
      this.topActions.push({
        id: 'new',
        template: 'new',
        label: this.newTopActionLabel,
        click: () => {
          this.chooseSupplierDialog = true
        },
        route: () => {
          if (this.orderTypes[this.tabModel] === this.orderTypeCredit) {
            return this.appendSitePrefix('/N0/order2/new_credit')
          } else if (this.orderTypes[this.tabModel] === this.orderTypeSupplier) {
            return null
          } else {
            return this.appendSitePrefix('/N0/order2/main')
          }
        },
      })
    }

    if (
      this.orderTypes[this.tabModel] !== this.orderTypeCredit &&
      (this.orderSubTypes[this.subTabModel] === 'open' || this.orderSubTypes[this.subTabModel] === 'offer') &&
      this.canCancelManyOrders
    ) {
      this.selectActions.push({
        id: 'cancelOrders',
        icon: 'fa-ban',
        color: 'red',
        class: 'white--text',
        label: 'c:order-list-view:Cancel orders',
        dense: true,
        click: () => {
          const ids = this.selectedRows.map(function (a) {
            return a.id
          })
          Vue.set(this.cancelOrdersModel, 'ids', ids)
          this.cancelOrdersDialog = true
        },
      })
    }
  }

  private setHeaders() {
    this.headers = []
    this.additionalFilters = []

    this.headers.push({
      text: 'c:order-list-view:Order ID',
      value: 'id',
    })

    if (this.orderTypes[this.tabModel] !== this.orderTypeSupplier) {
      this.headers.push({
        text: 'c:order-list-view:License plate',
        value: 'carLicenseplate',
      })
      this.headers.push({
        text: 'c:order-list-view:Customer',
        value: 'customerName',
      })

      if (this.isShowDetailsVisible && this.showDetailsCheckbox) {
        this.headers.push({
          text: 'c:order-list-view:Payer',
          value: 'payerName',
        })
        this.headers.push({
          text: 'c:order-list-view:References',
          value: 'customerReference',
        })
        this.headers.push({
          text: 'c:order-list-view:Internal note',
          value: 'internalNote',
        })
      }
    }

    if (this.orderTypes[this.tabModel] === this.orderTypeSupplier) {
      this.headers.push({
        text: 'c:order-list-view:Supplier',
        value: 'suppliers',
        sortable: false,
        filter: {
          items: this.supplierConnections,
          itemText: 'productSupplier.name',
          itemValue: 'productSupplier.id',
        },
      })
    }

    this.headers.push({
      text: 'c:order-list-view:Gross price',
      value: 'grossAmount',
      filter: { disable: true },
    })

    if (
      this.isShowDetailsVisible &&
      this.showDetailsCheckbox &&
      (this.orderTypes[this.tabModel] === this.orderTypeExternal ||
        this.orderTypes[this.tabModel] === this.orderTypeWebshopPreOrder)
    ) {
      this.headers.push({
        text: 'c:order-list-view:Profit',
        value: 'profitPercentage',
        filter: { disable: true },
      })
    }

    if (
      this.orderTypes[this.tabModel] !== this.orderTypeSupplier &&
      this.orderTypes[this.tabModel] !== this.orderTypeCredit
    ) {
      this.headers.push({
        text: 'c:order-list-view:Booking',
        value: 'booking.time',
        filter: {
          date: true,
          allowFutureDate: true,
        },
      })

      if (!this.hideBookingsWithoutGoodsCheckbox) {
        this.additionalFilters.push({
          key: 'bookingType',
          label: 'c:order-list-view:Booking type',
          items: [
            {
              text: this.$t('c:common:All'),
              value: 'all',
            },
            {
              text: this.$t('c:order-list-view-booking-type:With goods'),
              value: 'with_goods',
            },
            {
              text: this.$t('c:order-list-view-booking-type:Without goods'),
              value: 'without_goods',
            },
          ],
        })
      }
    }

    if (
      this.orderTypes[this.tabModel] !== this.orderTypeSupplier &&
      this.orderTypes[this.tabModel] !== this.orderTypeCredit &&
      this.orderSubTypes[this.subTabModel] === 'closed'
    ) {
      this.additionalFilters.push({
        key: 'closeReason',
        label: 'c:order-list-view:Close reason',
        multiple: false,
        equalsOperatorOnly: true,
        items: [
          {
            text: this.$t('c:order-list-view-close-reason:Is paid'),
            value: 1,
          },
          {
            text: this.$t('c:order-list-view-close-reason:Is billed'),
            value: 2,
          },
          {
            text: this.$t('c:order-list-view-close-reason:Is cancelled'),
            value: 3,
          },
          {
            text: this.$t('c:order-list-view-close-reason:Is stock move'),
            value: 4,
          },
        ],
      })
    }

    if (this.orderTypes[this.tabModel] !== this.orderTypeSupplier) {
      this.headers.push({
        text: 'c:order-list-view:Suppliers',
        value: 'suppliers',
        sortable: false,
        filter: {
          items: this.supplierConnections,
          itemText: 'productSupplier.name',
          itemValue: 'productSupplier.id',
        },
      })
    }

    this.headers.push({
      text: '*',
      value: 'isStarred',
      width: '36px',
      sortable: false,
      filter: { disable: true },
    })

    if (this.orderTypes[this.tabModel] !== this.orderTypeCredit) {
      this.headers.push({
        text: 'c:order-list-view:Status',
        value: 'statuses',
        filter: { disable: true },
        sortable: false,
        width: (this.isShowDetailsVisible && this.showDetailsCheckbox ? '100' : '60') + 'px',
      })
    }

    if (
      this.orderTypes[this.tabModel] === this.orderTypeExternal ||
      this.orderTypes[this.tabModel] === this.orderTypeWebshopPreOrder
    ) {
      if (this.isShowDetailsVisible && this.showDetailsCheckbox) {
        this.headers.push({
          text: 'c:order-list-view:Deliver to',
          value: 'deliverTo',
          filter: { disable: true },
        })
      }
      if (this.hasMultipleWebshops) {
        this.headers.push({
          text: 'c:order-list-view:Webshop',
          value: 'webshopId',
          filter: {
            items: this.webshops,
            itemText: 'title',
            itemValue: 'id',
          },
        })
      }
    }

    if (
      this.isShowDetailsVisible &&
      this.showDetailsCheckbox &&
      this.orderTypes[this.tabModel] !== this.orderTypeSupplier &&
      this.orderTypes[this.tabModel] !== this.orderTypeCredit
    ) {
      this.headers.push({
        text: 'c:order-list-view:Tyre hotel',
        value: 'tyreHotelId',
        filter: { disable: true },
        sortable: false,
      })
    }

    this.headers.push({
      text: 'Date',
      value: 'createdAt',
      filter: {
        date: true,
        allowFutureDate: false,
      },
    })

    this.headers.push({
      text: 'Actions',
      value: 'actions',
    })

    if (this.tags.length) {
      this.additionalFilters.push({
        key: 'tags',
        label: 'c:item-tag:Tags',
        multiple: true,
        custom: 'tag',
        items: this.tags.map((tag) => {
          return {
            text: tag.name,
            value: tag.id,
            textColor: tag.textColor,
            backgroundColor: tag.backgroundColor,
            icon: tag.icon,
          }
        }),
      })
    }
  }

  private formatPrice(value, currency) {
    const formatter = new Intl.NumberFormat('sv-SE', {
      style: 'currency',
      currency: currency,
      currencyDisplay: 'code',
      useGrouping: true,
      maximumFractionDigits: 2,
    })
    return formatter.format(value)
  }

  private getStatusColor(status) {
    if (status.status === 'cancelled') {
      return 'red'
    } else if (status.status === 'sent') {
      return 'blue'
    } else if (status.is_ok) {
      return 'green'
    } else {
      return ''
    }
  }

  private getPaymentStatusColor(status) {
    if (status.status === 'not_paid') {
      return 'red'
    } else {
      return 'green'
    }
  }

  private getDoneStatusColor(status) {
    if (status.status === 'cancelled') {
      return 'red'
    } else if (status.status === 'sent') {
      return 'blue'
    }
  }

  @Watch('$route', { deep: true })
  private routeToParams() {
    if (this.$route.query.orderType !== undefined) {
      this.onTabChange(this.orderTypes.indexOf(this.$route.query.orderType.toString()))
    } else {
      this.onTabChange(0)
    }
    if (this.$route.query.orderStatus !== undefined) {
      this.onSubTabChange(this.orderSubTypes.indexOf(this.$route.query.orderStatus.toString()))
    } else {
      this.onSubTabChange(0)
    }
    this.showDetailsCheckbox = !!localStorage.getItem(this.detailsSettingsKey)

    if (this.$route.query.onlyStarred !== undefined) {
      this.onlyStarredCheckbox = this.$route.query.onlyStarred.toString() === 'true'
      localStorage.setItem(this.onlyStarredKey, this.onlyStarredCheckbox ? '1' : '')
    } else {
      this.onlyStarredCheckbox = !!localStorage.getItem(this.onlyStarredKey)
    }

    if (this.$route.query.hideBookingsWithoutGoods !== undefined) {
      this.hideBookingsWithoutGoodsCheckbox = this.$route.query.hideBookingsWithoutGoods.toString() === 'true'
      localStorage.setItem(this.hideBookingsWithoutGoodsKey, this.hideBookingsWithoutGoodsCheckbox ? '1' : '')
    } else {
      this.hideBookingsWithoutGoodsCheckbox = !!localStorage.getItem(this.hideBookingsWithoutGoodsKey)
    }

    this.setHeaders()
    this.setTopActions()
  }

  @Watch('showDetailsCheckbox')
  private onShowDetailsChanged() {
    this.setHeaders()
    localStorage.setItem(this.detailsSettingsKey, this.showDetailsCheckbox ? '1' : '')
  }

  @Watch('onlyStarredCheckbox')
  private onOnlyStarredChanged() {
    localStorage.setItem(this.onlyStarredKey, this.onlyStarredCheckbox ? '1' : '')
  }

  @Watch('hideBookingsWithoutGoodsCheckbox')
  private onHideBookingsWithoutGoodsChanged() {
    localStorage.setItem(this.hideBookingsWithoutGoodsKey, this.hideBookingsWithoutGoodsCheckbox ? '1' : '')
  }

  @Watch('tabModel')
  private onTabModelChanged() {
    this.onlyStarredCheckbox = !!localStorage.getItem(this.onlyStarredKey)
    this.hideBookingsWithoutGoodsCheckbox = !!localStorage.getItem(this.hideBookingsWithoutGoodsKey)
  }

  @Watch('subTabModel')
  private onSubTabModelChanged() {
    this.onlyStarredCheckbox = !!localStorage.getItem(this.onlyStarredKey)
    this.hideBookingsWithoutGoodsCheckbox = !!localStorage.getItem(this.hideBookingsWithoutGoodsKey)
  }

  private createSupplierOrder(): void {
    this.$router.push(
      appendSitePrefix(
        '/N0/order2/main?supplier=' +
          this.choseSupplierDialogSupplierId +
          '&currency=' +
          this.choseSupplierDialogCurrency,
      ),
    )
  }

  private finalizeCancelOrders(): void {
    this.$axios
      .post('/v4/site/order/cancel', this.cancelOrdersModel)
      .then(() => {
        this.listViewKey += 1
        this.cancelOrdersDialog = false
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err)
      })
  }

  private onCancelOrdersModelChanged(value, key) {
    Vue.set(this.cancelOrdersModel, key, value)
  }

  private onSelectedRowsChanged(data) {
    this.selectedRows = data.selectedRows
  }

  private onTabChange(tabIndex) {
    this.tabModel = tabIndex
  }

  private onSubTabChange(buttonIndex) {
    this.subTabModel = buttonIndex
  }

  private get url() {
    return '/v4/site/order'
  }

  private get query() {
    return {
      orderType: this.orderTypes[this.tabModel],
      orderStatus: this.orderSubTypes[this.subTabModel],
      onlyStarred: this.onlyStarredCheckbox,
      hideBookingsWithoutGoods: this.hideBookingsWithoutGoodsCheckbox,
    }
  }

  private get tabs() {
    const tabs = ['c:order-list-view:Admin orders']
    if (this.canSeeExternalOrders) {
      tabs.push('c:order-list-view:External order')
    }
    if (this.canSeeSupplierOrders) {
      tabs.push('c:order-list-view:Supplier order')
    }
    if (this.canSeeCreditOrders) {
      tabs.push('c:order-list-view:Credit order')
    }
    if (this.hasWebshopPreOrders) {
      tabs.push('c:order-list-view:Webshop pre orders')
    }
    return tabs
  }

  private get orderTypes() {
    const orderTypes = [this.orderTypeAdmin]
    if (this.canSeeExternalOrders) {
      orderTypes.push(this.orderTypeExternal)
    }
    if (this.canSeeSupplierOrders) {
      orderTypes.push(this.orderTypeSupplier)
    }
    if (this.canSeeCreditOrders) {
      orderTypes.push(this.orderTypeCredit)
    }
    if (this.hasWebshopPreOrders) {
      orderTypes.push(this.orderTypeWebshopPreOrder)
    }
    return orderTypes
  }

  private get subTabs() {
    const result = []
    if (this.orderTypes[this.tabModel] === this.orderTypeAdmin) {
      result.push(new SubTab({ text: 'c:order-list-view:Show open orders' }))
      result.push(new SubTab({ text: 'c:order-list-view:Show closed orders' }))
      result.push(new SubTab({ text: 'c:order-list-view:Show offers' }))
      result.push(new SubTab({ text: 'c:order-list-view:Show drafts' }))
    } else if (this.orderTypes[this.tabModel] === this.orderTypeCredit) {
      result.push(new SubTab({ text: 'c:order-list-view:Show open credit orders' }))
      result.push(new SubTab({ text: 'c:order-list-view:Show closed credit orders' }))
    } else if (this.orderTypes[this.tabModel] === this.orderTypeExternal && this.hasWebshopReservationOrders) {
      result.push(new SubTab({ text: 'c:order-list-view:Show open orders' }))
      result.push(new SubTab({ text: 'c:order-list-view:Show closed orders' }))
      result.push(new SubTab({ text: 'c:order-list-view:Show reservations' }))
    } else {
      result.push(new SubTab({ text: 'c:order-list-view:Show open orders' }))
      result.push(new SubTab({ text: 'c:order-list-view:Show closed orders' }))
    }

    return result
  }

  private get orderSubTypes() {
    if (this.orderTypes[this.tabModel] === this.orderTypeAdmin) {
      return ['open', 'closed', 'offer', 'draft']
    } else if (this.orderTypes[this.tabModel] === this.orderTypeExternal && this.hasWebshopReservationOrders) {
      return ['open', 'closed', 'reservation']
    } else {
      return ['open', 'closed']
    }
  }

  private get isShowDetailsVisible() {
    return (
      this.orderTypes[this.tabModel] !== this.orderTypeCredit &&
      this.orderTypes[this.tabModel] !== this.orderTypeSupplier
    )
  }

  private get newTopActionLabel() {
    if (this.orderTypes[this.tabModel] === this.orderTypeCredit) {
      return 'c:order-list-view:New credit order'
    } else if (this.orderTypes[this.tabModel] === this.orderTypeSupplier) {
      return 'c:order-list-view:New supplier order'
    } else {
      return 'c:order-list-view:New order'
    }
  }

  private get detailsSettingsKey() {
    return 'order-list-view-show-details-' + this.tabModel + '-' + this.subTabModel
  }

  private get onlyStarredKey() {
    return 'order-list-view-only-starred-' + this.tabModel + '-' + this.subTabModel
  }

  private get hideBookingsWithoutGoodsKey() {
    return 'order-list-hide-bookings-without-goods-' + this.tabModel + '-' + this.subTabModel
  }

  private get finalizeCancelOrdersButtonDisabled() {
    return (
      !this.cancelOrdersModel.cancelOrder &&
      !this.cancelOrdersModel.returnProductsToStock &&
      !this.cancelOrdersModel.cancelBooking &&
      !this.cancelOrdersModel.cancelBatchInvoicing &&
      !this.cancelOrdersModel.cancelPickingRequest
    )
  }

  private onSearch(search) {
    this.search = search
  }

  private downloadExcel() {
    this.exportExcelLoading = true
    const queryParams = []

    Object.entries(this.query).forEach(([filter, value]) => {
      queryParams.push(`${filter}=${encodeURIComponent(value)}`)
    })
    queryParams.push(`search=${encodeURIComponent(this.search)}`)
    queryParams.push('searchToFilters=1')
    queryParams.push(`showDetails=${this.isShowDetailsVisible && this.showDetailsCheckbox}`)

    let url = '/v4/site/reports/OrdersListing/get-excel'

    if (queryParams.length > 0) {
      url += '?' + queryParams.join('&')
    }

    this.$axios
      .get(url)
      .then((response) => {
        const blob = b64toBlob(
          response.data.data.data,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        )
        const filename = this.generateFilename()
        if (!window.navigator.msSaveOrOpenBlob) {
          // BLOB NAVIGATOR
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.setAttribute('download', filename)
          document.body.appendChild(a)
          a.click()
        } else {
          // BLOB FOR EXPLORER 11
          window.navigator.msSaveOrOpenBlob(blob, filename)
        }
      })
      .finally(() => {
        this.exportExcelLoading = false
      })
  }

  private generateFilename() {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = String(currentDate.getMonth() + 1).padStart(2, '0')
    const day = String(currentDate.getDate()).padStart(2, '0')

    const hours = String(currentDate.getHours()).padStart(2, '0')
    const minutes = String(currentDate.getMinutes()).padStart(2, '0')
    const seconds = String(currentDate.getSeconds()).padStart(2, '0')
    const datetime = `${year}${month}${day}_${hours}${minutes}${seconds}`

    return `orders_listing_${datetime}.xlsx`
  }
}
